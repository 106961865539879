require('./admin/bootstrap.js')
require('bootstrap-datepicker')
require('jquery-validation')
require('jquery-mask-plugin')
require('dragscroll')
require('touch-dnd/touch-dnd')

require('medium-editor')
import MediumEditor from 'medium-editor'

window.Popper = require('popper.js');

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';


$(function () {
	/*
	** Editor
	*/
		var editor = new MediumEditor('.editable', {
			toolbar: {
				buttons: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3']
			},
			placeholder: {
				text: 'Selecione para editar',
				hideOnClick: false
			},
			anchor: {
				linkValidation: true,
				placeholderText: 'Insira um link',
				// targetCheckbox: true,
				// targetCheckboxText: 'Abrir em nova aba'
			}
		})

	/*
  	** Auto height no textarea
  	*/
		$('textarea[rows=1]').each(function () {
			this.setAttribute('style', 'height:' + this.scrollHeight + 'px;overflow-y:hidden;')
		}).on('input', function () {
			this.style.height = 'auto'
			this.style.height = (this.scrollHeight) + 'px'
		})

		$(window).on('resize', function() {
			$('textarea[rows=1]').each(function () {
				let self = this
				setTimeout(function(){
					self.style.height = 'auto'
					self.style.height = (self.scrollHeight) + 'px'
				},100)
			})
		})

	
	/*
  	** Block selector fullscreen
  	*/
		// $('#bsel').on('show.bs.dropdown', function () {
		//   	$('body').addClass('modal-open');
		// });
		// $('#bsel').on('hide.bs.dropdown', function () {
		//   	$('body').removeClass('modal-open');
		// });


	/*
	** Datepicker
	*/
		$.fn.datepicker.dates['en'] = {
		    days: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
		    daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
		    daysMin: ["D", "S", "T", "Q", "Q", "S", "S"],
		    months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
		    monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
		    today: "Hoje",
		    clear: "Limpar",
		    format: "dd/mm/yyyy",
		    titleFormat: "MM - yyyy",
		    weekStart: 0
		}

		$('[data-type=date]').datepicker({
			todayHighlight: true,
		    autoclose: true,
		    todayBtn: true,
		    container: '#picker'
		})

	
	/*
  	** Form validation/mask
  	*/
		$('form[data-validate=true]').validate({
			errorPlacement: function(error, element) {
				const getErrors = document.querySelectorAll('form :invalid')

				if (getErrors.length > 0) {
					const inTabs = getErrors[0].closest('.tab-pane')

					if (inTabs) {
						document.querySelector(`[href="#${inTabs.id}"]`).click()
					}
				}
			},
			highlight: function(element) {
		        $(element).closest('.form-group').addClass('has-error')
		    },
		    unhighlight: function(element) {
		        $(element).closest('.form-group').removeClass('has-error')
		    },
		})

		$(document).ready(function(){
			$('[data-type="cpf"]').mask('ZZZ.ZZZ.ZZZ-ZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } })
			$('[data-type="cnpj"]').mask('ZZ.ZZZ.ZZZ/ZZZZ-ZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } })
			$('[data-type="phone"]').mask('(ZZ) ZZZZ-ZZZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } })
			$('[data-type="cell"]').mask('(ZZ) Z ZZZZ-ZZZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } })
			$('[data-type="date"]').mask('ZZ/ZZ/ZZZZ', { translation: { 'Z': { pattern: /[0-9]/, optional: false } } })
			$('[data-type="money"]').mask("ZZZ.ZZZ.ZZZ.ZZZ,ZZ", { reverse: true, translation: { 'Z': { pattern: /[0-9]/, optional: false } } })
		})

	
	/*
  	** Navbar
  	*/
		$('#header').on('show.bs.dropdown', function(){
		    $('body').addClass('modal-open')
		})

		$('#header').on('hide.bs.dropdown', function(){
		    return false
		})

		$('.navbar-dismiss').on('click', function(){
			$('#header').removeClass('show')
			$('body').removeClass('modal-open')
		})

		$('.sublink-active').each( function () {
			let trigger = $( this ).parent().parent().parent().find( '[data-toggle="collapse"]' )
	    	trigger.click()
	  	})

	
	/*
  	** Tooltip
  	*/
		$('[data-toggle="tooltip"]').tooltip({
			container: 'body',
			trigger: 'hover',
			html: true
		})

	
	/*
	** Special buttons
	*/
		$('#ordenar').on('hide.bs.modal', function (e) {
		  	setTimeout( function () {
		    	window.location.reload()
		    }, 100 )
		})

	
	/*
	** Change item status
	*/
		$( '.change-status' ).click( function () {
			let item_id = this.value
			$.ajax({
				type: 'GET'
				, url: `${window.Route}/${item_id}/status`
			})
		})


	/*
	** File name (input[type="file"])
	*/
		$('input[type="file"]').change(function () {
			let fileName = $(this).parent().find('.form-control')
			let filePlaceholder = $(this).parent().data('placeholder')

			if (!this.files.length) {
				fileName.html(`<span class="placeholder">${filePlaceholder}</span>`)
			} else if ( this.files.length === 1 ) {
				fileName.html(this.files[0].name)
			} else {
				fileName.html(`${this.files.length} arquivos selecionados`)
			}
		})

	
	/*
	** Contador de caracteres
	*/
		let Counter = function (maxlength) {
			let counter_badge = document.createElement( 'span' )
			counter_badge.className = 'badge circle form-counter-label'

			counter_badge.set_counter = function ( chars ) {
				let message = ''
				if ( chars > 1 ) {
					message = `${chars} caracteres restantes.`
				} else if ( chars === 0 ) {
					message = `Limite atingido.`
				} else {
					message = `1 caractere restante.`
				}
				counter_badge.innerText = message
			};

			return counter_badge
		}


	/*
	** Chama os inputs com contador
	*/
		$('.form-counter').each(function() {
			var maxlength = this.getAttribute( 'maxlength' ) * 1

			if (!maxlength) {
				return console.error( 'Defina um número máximo de caracteres para o contador.' );
			}

			let counter = new Counter(maxlength)
			counter.set_counter( maxlength - this.value.length )
			this.parentNode.appendChild(counter)
			$(this).keyup( function () {
				counter.set_counter( maxlength - this.value.length )
			}).focusout( function () {
				counter.set_counter( maxlength - this.value.length )
			})
		})


	/*
	** Sortable
	*/
		// const setOrder = function () {
		// 	let message = $(this).parent().find('.message-order');
			
		// 	setTimeout(() => {
		// 		let childs = this.children;

		// 		let ids = [];
				
		// 		for (let i = 0, l = childs.length; i < l; i++) {
		// 			childs[ i ].dataset.id && ids.push( childs[ i ].dataset.id);
		// 		}

		// 		$.ajax({
		// 			url: `${window.Route}/reorder`
		// 			, type: 'GET'
		// 			, data: { order: ids.join( ';' ) }
		// 			, success: function ( data ) {
		// 				if ( data && data.success ) { message.removeClass( 'd-none' ) }
		// 			}
		// 		})
		// 	}, 200)
		// }

		// $('.sortable-photo').sortable({
		// 	handle: 'img',
		// 	placeholder:'placeholder',
		// 	forcePlaceholderSize: true
		// }).on('sortable:deactivate', function (e, ui) {
		// 	setOrder.apply(this);
	  	// })

		// $('.sortable-text').sortable({
		// 	handle: '.link-default',
		// }).on('sortable:deactivate', function (e, ui) {
		// 	setOrder.apply( this );
	  	// })

		//   $('.sortable-files').sortable({
		// 	handle: '.handle',
		// 	}).on('sortable:deactivate', function (e, ui) {
		// 		setOrder.apply( this );
		// 	})

})